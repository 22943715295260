@import "src/theme";

section#getting-started-page-new {
  .loading-button-container > a {
    color: white;
  }

  p,
  li {
    font-size: 16px;
  }

  .elevated-section {
    box-shadow: 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.11), 0 3.2px 7.2px 0 rgba(0, 0, 0, 0.13);
    padding: 10px 0px 30px;
  }

  .isolated-text {
    justify-content: center;
    text-align: center;
    padding: 0px 10%;
    margin: 30px 0px;
    margin-bottom: 50px;
    font-size: 20px;
  }

  .inverted-button button {
    background-color: transparent;
    color: #0078d4;
  }

  .biggest-heading {
    font-size: 36px;
    color: white;
    background-color: #213b62;
  }

  .button-wrapper {
    justify-content: center;
    display: flex;
  }

  section {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  @media screen and (min-width: $r-stop-2) {
    .action-card-row {
      display: flex;
    }
  }

  p#banner-sub-heading {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.profile-edit-policy {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.discount-donation-policies {
  margin-bottom: 2.5rem;
}

.profile-edit-button {
  width: 10%;
}

.profile-cancel-button {
  display: block;
  margin: 0 20px;
  border: 2px solid #0067b8;
}

.profile-cancel-confirm-button {
  margin-right: 10px;
  padding: 10px;
  color: #ffffff;
  background-color: #000000;
  width: 20%;
}

.profile-submit-edit-buttons {
  display: flex;
}

.flex-end {
  justify-content: flex-end;
}

.profile-confirm-modal {
  z-index: 11;
  background-color: #ffffff;
  padding: 1rem;
  width: 638px;
  align-self: center;
  // height: 394px;
}

.profile-confirm-modal-backdrop {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000099;
  z-index: 10;
}

.thanks-for-updating {
  font-weight: 600;
  padding: 2rem 0 1rem 0;
}

.profile-form {
  padding-left: 10%;
  padding-right: 10%;

  .ms-Grid-row {
    display: flex;
    flex-wrap: wrap;
  }
}

.error {
  color: #e50000;
}

.input:focus {
  outline: none;
}

.form-control:focus {
  outline: none;
}

.button:focus {
  outline: none;
}

.custom-select-input:focus {
  outline: 0.125rem solid rgb(0,120,212);
}
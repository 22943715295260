.loading-button-container {
  display: flex;
  align-items: center;

  .ms-Spinner {
    margin: 0 15px;
  }

  i {
    font-size: 14px;
  }
  .icon-button-props {
    padding: 20px;
  }
}

.info-section {
  background: #fafafa;

  @media screen and (min-width: 860px) {
    padding: 2px 44px;
  }

  @media screen and (max-width: 860px) {
    padding: 2px 0px;
  }
}

.info-section-header-container {
  padding: 43px 0px;
}

@import "src/theme";

#user-panel {
  right: 0;
  z-index: 1000;
  position: absolute;
  width: 0px;

  .user-panel-button {
    @include base-button();
    width: 32px;
    height: 32px;

    background-size: 32px 32px;
    padding: 3px 6px;

    float: right;
    margin: 14px 0;
    text-transform: uppercase;
    font-size: 18.5px;
    color: #666666;
  }

  @media screen and (max-width: $r-stop-1) {
    margin-right: 10px;
  }
}

div#user-panel-content {
  $aside-wd: 25em;
  $aside-ht: 12.3em;
  $aside-ht2: 12.3em;

  right: 0;
  z-index: 1000;
  width: $aside-wd;
  margin-top: 50px;
  position: absolute;

  @media screen and (max-width: $r-stop-3) {
    width: 100%;
  }

  aside {
    top: $header-ht - 14;
    width: $aside-wd;
    height: fit-content;
    color: $user-panel-txt-clr;
    text-align: left;
    float: right;
    z-index: 1001;

    @media screen and (max-width: $r-stop-3) {
      width: 100%;
    }

    &.hide {
      transition: 0.2s;
      opacity: 0;
      display: none;
    }

    &.show {
      transition: 0.3s;
      opacity: 1;
      top: $header-ht;
      height: fit-content;

      > div {
        transition: 0.4s;
        height: fit-content;
      }
    }

    &.show-without-profile {
      transition: 0.3s;
      opacity: 1;
      top: $header-ht;
      height: fit-content;

      > div {
        transition: 0.4s;
        height: fit-content;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      float: right;
      width: $aside-wd;
      height: $aside-ht - 4em;
      background-color: $user-panel-bkg-clr;
      border: $user-panel-border;
      overflow: hidden;

      @media screen and (max-width: $r-stop-3) {
        width: 90%;
      }

      /* Badge */
      > div {
        height: fit-content;

        > div {
          display: inline-block;
          vertical-align: top;
          margin: 12px;

          /* User photo */
          &:first-of-type {
            height: $user-badge-ht-lg;
            width: $user-badge-ht-lg;
            background-image: url(../../assets/images/aad_enabled.png);
            background-size: $user-badge-ht-lg $user-badge-ht-lg;
            background-position: left center;

            margin-right: 10px;
          }

          /* Name and email */
          &:nth-of-type(2) {
            /* Name */
            > div:first-of-type {
              margin: 0 !important;
              padding-top: 2px !important;
              font-size: 22px;
            }

            /* Email address */
            > div:nth-of-type(2) {
              font-size: 12px;
              margin-top: 5px;
              width: 100%;

              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      /* Menu */
      ul {
        @include base-list();

        clear: both;

        li {
          font-size: 16px;
          margin: 0 !important;

          &:hover {
            background-color: darken($user-panel-bkg-clr, 10%);
          }

          p {
            margin: 0;
            padding: 0;
          }

          a {
            padding: 5px 10px 5px 10px !important;
            display: block;
            vertical-align: middle;
            text-decoration: none;
            color: #262626;
          }

          button {
            padding: 5px 10px 5px 10px !important;
            display: block;
            vertical-align: middle;
            text-decoration: none;
            color: #262626;
            border-width: 0;
            background-color: transparent;
            width: 100%;
            text-align: left;
          }
          

          #dismissDialogRegion {
            padding: 0px !important;
          }
        }
      }
    }

    &.dismiss {
      height: $aside-ht2 !important;

      > div {
        height: $aside-ht2;
      }
    }
  }
}

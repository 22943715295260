@import "src/theme";

#squares-banner {
  background-repeat: no-repeat;
  width: 100%;
  margin-left: 0px;
  margin-top: 0px;
  color: white;
  display: table;
  align-items: center;

  @media screen and (min-width: $r-stop-0) {
    #banner-text {
      margin-bottom: 200px;
    }
  }

  @media screen and (max-width: $r-stop-2) {
    background-size: auto;
    height: auto;
    background-position: right;
  }

  #banner-region {
    margin-top: 0px;

    #banner-title {
      margin-left: 0px;
      padding-left: 20px;
      padding-top: 30px;
      padding-right: 20px;
      padding-bottom: 80px;
    }

    #banner-text {
      margin-left: 20px;
      margin-top: -85px;
      z-index: 9999;
      padding: 10px;
      padding-left: 0px;
      padding-right: 20px;
    }
  }
}

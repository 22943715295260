.azure-grant-disclaimer {
  padding: 0px;

  .disclaimer-text {
    @media screen and (min-width: 1920px) {
      padding: 24px 165px;
    }

    @media screen and (min-width: 1400px) and (max-width: 1920px) {
      padding: 24px 70px;
    }

    @media screen and (min-width: 1084px) and (max-width: 1400px) {
      padding: 24px 56px;
    }

    @media screen and (max-width: 1084px) {
      padding: 24px 12px;
    }

    .disclaimer-paragraph {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      margin: 0px;

      .disclaimer-link {
        font-size: 12px;
      }
    }
  }
}

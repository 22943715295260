.locale-picker-container {
  padding: 1rem 4rem 1rem 4rem;
}

.locale-link {
  text-decoration: none !important;
  font-weight: 600 !important;

  &:hover {
    text-decoration: underline !important;
  }
}

.locale-header {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
  font-weight: 600 !important;
  font-size: 34px;
  line-height: 40px;
}

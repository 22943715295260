.azure-status-icon {
  color: #e81123;
  font-size: 24px;
  line-height: 24px;
}

.error-message-title {
  color: #e81123;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
}

a[href]:focus {
  outline: 1px dotted black;
}

.more-details-button > button:focus {
  outline: 1px dotted black;
}

.action-step-card {
  background-color: #ffffff;

  @media screen and (min-width: 860px) {
    min-width: 264px;
  }

  @media screen and (min-width: 540px) and (max-width: 860px) {
    min-width: 516px;
  }

  .icon-container-card {
    @media screen and (min-width: 860px) {
      min-width: 264px;
      height: 148px;
    }

    @media screen and (min-width: 540px) and (max-width: 860px) {
      min-width: 516;
      height: 290px;
    }

    @media screen and (max-width: 540px) {
      height: 190px;
    }

    background-color: #f2f2f2;

    .card-img {
      height: 80px;
      width: 80px;
    }
  }

  .step-card-body {
    #title {
      font-size: 21px;
    }

    padding-top: 12px;
  }

  .action-link {
    padding-top: 24px;
    line-height: 0px;
    padding-bottom: 24px;
    
    .cta-enabled-style {
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #ffffff;
      color: #0067b8;
      .focus{
        outline: 1px dotted black;
      }
    }

    .cta-disabled-style {
      background-color: #ffffff;
      color: #a2a2a2;
      pointer-events: none;
    }
  }
}

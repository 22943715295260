@import "src/theme";

section#getting-started-page {
  a {
    color: white;
  }

  #getting-started-banner {
    background: linear-gradient(to right, rgb(22, 22, 22), transparent), url("../../assets/images/getting-started-old.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 431px;
    margin-left: 0px;
    margin-top: 0px;
    color: white;
    display: table;
    align-items: center;

    @media screen and (max-width: $r-stop-2) {
      background-size: auto;
      height: auto;
      background-position: right;
    }

    #banner-title {
      font-size: 40px;
      margin-top: 70px;
      margin-bottom: 20px;
    }

    #banner-text {
      margin-bottom: 30px;
      background-color: transparent !important;

      p {
        font-size: 20px;
        margin: 0px;
      }

      #disclaimer-rf {
        text-decoration: none;
      }
    }

    #banner-button-faq,
    #banner-button-eligibility {
      margin-top: 10px;
    }
  }

  #banner-region {
    margin: 40px;
  }

  .getting-started-section {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 15px;
  }

  #getting-started-disclaimer {
    a {
      color: black;
      text-decoration: none;
    }

    p {
      font-size: small;
    }
  }
}

.info-card {
  .icon-container-card {
    height: 174px;
    width: 308px;
    background-color: #f2f2f2;

    .card-img {
      height: 105px;
      width: 105px;
    }
  }

  .step-card-body-title {
    padding-top: 12px;
  }

  .step-card-body-detail {
    padding-top: 12px;
  }
}

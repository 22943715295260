@import "src/theme";

.dynamic365V2 {
  .highlightable-cards {
    display: grid;

    @media screen and (min-width: 541px) {
      .one {
        grid-column: 1;
        grid-row: 1;
      }

      .two {
        grid-column: 2;
        grid-row: 1;
      }

      .three {
        grid-column: 3;
        grid-row: 1;
      }

      .four {
        grid-column: 4;
        grid-row: 1;
      }

      .five {
        grid-column: 1;
        grid-row: 2;
      }

      .six {
        grid-column: 2;
        grid-row: 2;
      }

      .seven {
        grid-column: 3;
        grid-row: 2;
      }

      .eight {
        grid-column: 4;
        grid-row: 2;
      }
    }

    @media screen and (max-width: 540px) {
      .one {
        grid-column: 1;
        grid-row: 1;
      }

      .two {
        grid-column: 2;
        grid-row: 1;
      }

      .three {
        grid-column: 1;
        grid-row: 2;
      }

      .four {
        grid-column: 2;
        grid-row: 2;
      }

      .five {
        grid-column: 1;
        grid-row: 3;
      }

      .six {
        grid-column: 2;
        grid-row: 3;
      }

      .seven {
        grid-column: 1;
        grid-row: 4;
      }

      .eight {
        grid-column: 2;
        grid-row: 4;
      }
    }

    @media screen and (min-width: 541px) {
      grid-template-columns: repeat(8, auto);
      grid-template-areas: "empt1 box1 box1 box2 box2 box4 box4 empt3"
                        "empt box5 box5 box6 box6 box7 box7 emp2";
      grid-template-rows: 1fr 1fr;

      .one-new {
        grid-area: box1;
      }

      .two-new {
        grid-area: box2;
      }

      .three-new {
        grid-area: box3;
      }

      .four-new {
        grid-area: box4;
      }

      .five-new {
        grid-area: box5;
      }

      .six-new {
        grid-area: box6;
      }

      .seven-new {
        grid-area: box7;
      }
    }

    @media screen and (max-width: 540px) {
      grid-template-columns: repeat(8, auto);
      grid-template-areas: "box1 box2"
                          "box4 box5"
                          "box6 box7";
      grid-template-rows: 1fr 1fr;

      .one-new {
        grid-area: box1;
      }

      .two-new {
        grid-area: box2;
      }

      .three-new {
        grid-area: box3;
      }

      .four-new {
        grid-area: box4;
      }

      .five-new {
        grid-area: box5;
      }

      .six-new {
        grid-area: box6;
      }

      .seven-new {
        grid-area: box7;
      }
    }

    .highlightable-card {
      height: 163px;
      border-radius: 0px;
      background-color: #243a5e;
      opacity: 0.5;
      color: white;
      text-align: center;
      margin: 15px;
      padding: 10px;
      vertical-align: middle;

      .inner-card {
        position: relative;
        top: 50%;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }

      h3 {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
      }

      .divider-line {
        border-top: white 2px solid;
        width: 70%;
        height: 0px;
        margin: 5px auto;
      }

      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: center;
      }
    }

    .highlighted {
      opacity: 1;
    }
  }

  .solutions-tabs {
    background: #f2f2f2;
    padding: 25px;

    .header-section {
      .solutions-header {
        text-align: center;
        margin: 10px;
      }

      .solutions-paragraph {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    .solutions-tab-group {
      text-align: center;

      .tablinks {
        color: #0067b8;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        margin: 10px;
        border: none;
        text-decoration: none;
        padding: 5px;

        @media screen and (max-width: 800px) {
          display: block;
        }
      }

      .tab-selected {
        color: #000000;
        border-bottom-color: #0067b8;
        border-bottom-style: solid;
        border-bottom-width: 2px;
      }
    }
  }

  .power-platform-background {
    overflow: hidden;

    .power-platform-background-img {
      height: 600px;
      min-width: 100%;
      text-align: center;
    }

    .power-platform {
      position: absolute;
      background-color: white;
      margin: 73px;
      height: 455px;
      width: 512px;
      border-radius: 0px;
      padding: 48px;
      letter-spacing: 0em;
      text-align: left;

      .power-platform-h1 {
        margin-bottom: 24px;
      }

      .power-platform-h3 {
        font-size: 21px;
        font-weight: 600;
        line-height: 24px;
      }

      .power-platform-p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }

      .power-platform-a {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-top: 48px;
      }

      @media screen and (max-width: 700px) {
        width: 73%;
        left: 0;
        margin: 73px 13.5%;
        padding: 24px;

        .power-platform-h1 {
          font-size: 35px;
          line-height: 45px;
        }
      }
    }
  }

  .ops-earn {
    background: #e6e6e6;

    .ops-earn-header {
      padding: 25px;

      .ops-earn-h1 {
        font-size: 29px;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 24px;
      }

      .ops-earn-h3 {
        text-align: center;
      }

      .ops-earn-p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
      }

      .ops-earn-a {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 20px;
      }
    }

    .ops-earn-group {
      text-align: center;

      .tablinks {
        color: #0067b8;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        margin: 10px;
        border: none;
        text-decoration: none;
        padding: 5px;
      }

      .tab-selected {
        color: #000000;
        border-bottom-color: #0067b8;
        border-bottom-style: solid;
        border-bottom-width: 2px;
      }
    }
  }
}

.steps-container-style {
  gap: 22px;
  display: flex;

  @media screen and (min-width: 1084px) {
    padding: 24px 56px 0px;
  }

  @media screen and (max-width: 1084px) {
    padding: 24px 40px 0px;
  }

  @media screen and (max-width: 860px) {
    flex-direction: column;
  }

  .step-card {
    flex: 1;
  }
}

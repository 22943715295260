@import "src/theme";

section#sign-in-cta {
  float: right;
  margin: 15px 0;
  text-align: center;
  display: flex;
  margin-right: 0;
  .signin-button {
    margin: auto;
    margin-left: 20px;
    margin-right: 0;
  }
  right: 0;
  position: absolute;
  .cta-text-div {
    width: 260px;
  }
  .cta-text {
    font-size: 15px;
  }

  @media screen and (max-width: $r-stop-0) {
    margin-top: 20px;
    .signin-button {
      margin: 0 5px 0 0;
    }
    .cta-text {
      display: none;
    }
    .mobileView {
      margin-top: -17px;
    }
  }
}

.bing-ads-info-section {
  display: flex;
  align-items: flex-start;
  .banner-icon {
    display: block;
    padding: 3px 8px 0 0;
  }
}

.bing-ads-modal {
  padding: 20px 24px 22px 16px;
  width: 638px;

  .header {
    display: flex;
    justify-content: space-between;
  }

  .form-group {
    margin: 0px;
    .actions {
      display: flex;
      justify-content: flex-end;
    }
  }

  .bing-ads-additional-resources {
    a li:focus {
      outline: 1px dotted black;
    }
  
    button:focus {
      outline: 1px dotted black;
    }

    li::marker {
      color: #005da6;
    }
  }
}

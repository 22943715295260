.partners-eligibility-success {
  background: #e6e6e6;
}

.software-licenses {
  text-align: center;
  background: #f2f2f2;
}

.partners-top-banner-container {
  max-height: 465px;
}

.partners-top-banner-image {
  top: -25%;
  max-width: 1160px;
}

.azure-intro-banner-title {
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
}

.description-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

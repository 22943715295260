@import "src/theme";

#header-panel {
  #header-container {
    background-image: url("../../assets/images/header-ms-log.png");
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 108px 23px;
    margin: 0;
    height: $header-ht;
    vertical-align: middle;
    position: relative;
    display: flex;
    align-items: center;

    #header-name {
      float: left;
      margin-left: 130px;
      color: #262626;
      font-size: 18px;
      font-weight: 600;
      border-left: solid 2px #555;
      padding-left: 20px;
      text-decoration: none;
    }

    > a {
      float: right;
      font-size: $nav-menu-font-size;
    }

    @media screen and (max-width: $r-stop-0) {
      background-position: center center;
      border-bottom: solid 1px #e6e6e6;
      margin: 0 !important;

      #header-name {
        display: none;
      }
    }
  }
}

#contact-us-section {
  background: #e6e6e6;

  @media screen and (min-width: 540px) {
    padding: 42px 24%;
  }

  @media screen and (max-width: 540px) {
    padding: 42px 12px;
  }

  .contact-us-header {
    text-align: center;
  }

  .contact-us-content {
    text-align: center;
  }
}

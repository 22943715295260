@import "src/theme";
#registration-social-section {
  .twitter-icon,
  .facebook-icon,
  .linkedin-icon {
    width: 28px;
    height: 28px;
    margin-left: 10px;
  }

  .facebook-icon:focus {
    outline: 0.125rem solid black;
  }

  .twitter-icon:focus {
    outline: 0.125rem solid black;
  }
}
.getting-started-top-banner-image {
  @media only screen
  and (min-width : 1084px) {
    background-color: white;
    padding-left: 50%;
    width: 100%;
  }

  @media only screen
  and (max-width : 1084px) {
    width: 100%;
  }
}

.getting-started-top-banner-background::after {
  @media only screen
  and (min-width : 1084px) {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, white 60%, transparent, transparent);
  }
}

.getting-started-top-banner-container {
  @media screen and (min-width: 1084px) {
    width: 95%;
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
}

.text-input {
  .validation-text {
    color: #e81123;
    font-weight: 400;
    font-size: 1rem;
  }

  .validation-icon {
    color: #e81123;
    font-size: 1rem;
    padding-right: 6px;
  }
}

@import "src/theme";

@media screen and (min-width: $r-stop-2) {
  .step-card-section {
    display: flex;
  }
}

.step-card-container {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 0px !important;

  @media screen and (min-width: $r-stop-0) {
    width: 32% !important;
    margin: 10px;
  }

  @media screen and (max-width: $r-stop-1) {
    margin-bottom: 40px;
  }

  .step-card-title {
    position: absolute;
    z-index: 9999;
    top: -20px;
    font-size: 24px;
    color: white !important;
    text-align: center;
    padding-top: 2px;
    padding-bottom: 2px;
    height: 40px;
    width: 170px;
    display: block;
    background: #7d7d7d;
    margin: 0 auto;
  }

  .step-card-title-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .step-card-image {
    width: 100%;
  }

  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  p > a {
    color: #0078d4;
    text-decoration: none;
  }

  .selected {
    display: block;
    background: #0078d4;
  }

  .step-card-content {
    color: "black";
    font-size: 16;
    padding: 0px 15px 30px;
  }
}

#registration-complete-card {
  .card-title {
    font-weight: bold;
    color: black;
    font-size: 18px;
    margin: 10px 0px 0px 14px;
    font-family: "Segoe UI light";
  }
  .card-title-icon {
    color: #0078d4 !important;
    font-size: 36px;
  }
  .chevron-icon {
    color: #0078d4 !important;
    font-size: 16px;
    font-weight: bold;
    font-family: "Segoe UI light";
  }
  .a:hover,
  a:active,
  a:visited,
  a:link {
    color: #0078d4 !important;
  }
}

.banner {
  background-color: #e6e6e6;
  padding: 24px 56px;

  .header-section {
    .icon-container {
      margin: 16px 0px 0px;
      width: 24px;
      height: 24px;

      .azure-status-icon {
        font-size: 24px;
        line-height: 24px;
      }
    }

    .banner-title {
      margin: 15.5px 0px 0px 24px;

      .title-text {
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 26px;
      }
    }
  }

  .description-section {
    margin: 16px 48px 15.5px 48px;

    .description-content {
      .description-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

@import "src/theme";

#profile-form {
  margin-top: 15px;
  padding: 0 !important;

  fieldset {
    margin: 0 0 10px 0;
    padding: 0;
    border: 0;
    min-width: 100px;

    legend {
      border-style: none;
      background-color: #2475c1;
      font-weight: 600;
      color: #ffffff;
      min-width: 200px;
      padding: 7px;
      margin: 0;

      h2 {
        font-size: 15px;
      }
    }

    .fields {
      border: 1px solid #71afe5;
      position: relative;
      top: 0px;
      left: 0px;
      padding: 5px 20px 5px 10px;
    }
  }

  .ms-Grid-row {

    &.field-row {
      padding: 3px 0;

      .ms-Grid-col {
        border: 0px solid #e6e6fa;
        padding: 4px 5px;

        &.field {
          font-weight: normal;
          font-size: 14px;

          label {
            display: inline;
            font-family: inherit;
            font-size: inherit;
          }

          i {
            cursor: pointer;
            vertical-align: top;
            font-size: 15px;
            height: 20px;
            width: 20px;
            margin-left: 5px;
          }
        }

        &.value {

          input,
          textarea,
          select {
            width: 100%;
            border: 1px solid lighten(rgb(16, 110, 190), 40%);
            padding: 3px;

            &.has-error {
              border-color: #e50000;
            }
          }

          select {
            -ms-box-sizing: content-box;
            -moz-box-sizing: content-box;
            box-sizing: content-box;
            -webkit-box-sizing: content-box;
          }

          ::placeholder {
            color: #757575;
          }

          textarea {
            overflow-x: hidden;
            resize: none;
          }

          &.disabled {
            border: 1px solid lighten(rgb(16, 110, 190), 40%);
            background-color: rgb(244, 244, 244);
            color: rgb(166, 166, 166);
            opacity: 50%;
            margin-left: 5px;
          }

          input:read-only,
          textarea:read-only {
            border: none;
            padding: 0;
          }

          input:disabled,
          textarea:disabled {
            background-color: rgb(244, 244, 244);
            color: rgb(166, 166, 166);

            &:read-only {
              color: inherit;
              background-color: inherit;
            }
          }

          font-size: 14px;

          &.split {
            border-right: 3px solid white;
          }

          .error {
            display: block;
            font-size: 12px;
            color: #e50000;
            font-style: italic;
          }

          ul.notification-emails {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              padding-bottom: 5px;
              font-size: 14px;

              .form-field {
                display: inline;

                input {
                  width: 70%;
                }

                .error {
                  display: inline;
                }
              }

              a {
                display: block;
              }
            }
          }
        }
      }
    }

    &.footer {
      .ms-Grid-col {
        &.buttons {
          display: flex;
        }
      }
    }

    &.profile-incomplete,
    &.profile-not-editable {
      color: #e50000;
      padding-bottom: 10px;
    }
  }

  i.add,
  i.remove {
    cursor: pointer;
    vertical-align: top;
    font-size: 20px;
    height: 20px;
    width: 20px;
    margin-left: 5px;
  }
}

.modal-container {
  padding: 20px;

  @media screen and (max-width: $r-stop-1) {
    margin: 0px 20px;
  }

  @media screen and (min-width: $r-stop-1) {
    width: $max-content-wd;
  }

  .modal-header {
    font-size: 20px;
  }

  p.modal-content {
    color: #e50000;
  }
}

.panel-container {
  p.panel-content {
    color: #e50000;
  }
}

#contact-permission-settings {
  margin-left: 3px;
  padding: 10px;
}

.banner {
  background-color: #e6e6e6;
  padding: 24px 56px;

  .home-banner-header-section {
    .home-banner-icon-container {
      margin: 6px 0px 0px;
      width: 24px;
      height: 24px;
      .status-icon {
        font-size: 24px;
        line-height: 24px;
      }
    }

    .banner-title {
      margin: 4px 48px 0px 24px;
    }
  }

  .home-banner-description-section {
    overflow: hidden;
    padding: 16px 48px 4px;
  }
}

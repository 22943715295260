@import "src/theme";

#registration-complete-banner {
  background-repeat: no-repeat;
  width: 100%;
  height: 431px;
  margin-left: 0px;
  margin-top: 0px;
  color: white;
  display: table;
  align-items: center;

  @media screen and (max-width: $r-stop-2) {
    background-size: auto;
    height: auto;
    background-position: right;
  }

  #banner-title {
    font-size: 36px;
    margin-top: 70px;
    margin-bottom: 20px;
  }

  #banner-text {
    margin-bottom: 30px;

    p {
      font-size: 16px;
      margin: 0px;
    }
  }

  #banner-button-learn-more {
    margin-top: 10px;
    font-size: 15px;
  }

  #banner-region {
    font-family: "Segoe UI light";
    margin: 40px;
    a {
      color: white;
    }
  }
}

@import "src/theme";

section#registration-complete-page {
  section {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .elevated-section {
    box-shadow: 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.11), 0 3.2px 7.2px 0 rgba(0, 0, 0, 0.13);
    padding: 10px 0px 30px;
  }
  .inverted-large-button {
    background-color: transparent;
    color: #0078d4;
    div {
      font-size: 20px;
    }
  }
  .button-wrapper {
    justify-content: center;
    display: flex;
  }
}

.pricing-disclaimer {
  @media screen and (min-width: 540px) {
    padding-left: 3rem;
    padding-right: 3rem;

    p.pricing-disclarimer-1 {
      padding-top: 3rem;
    }

    p.pricing-disclarimer-2 {
      padding-bottom: 3rem;
    }
  }

  @media screen and (max-width: 540px) {
    padding: 12px 12px;
  }
}

.pricing-disclaimer p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
}

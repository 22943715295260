.container-style {
  width: auto;
  height: 94px;
  background-color: #ffffff;
  padding: 24px 56px;

  .text-box-style {
    height: 46px;
    width: 972px;

    .text-style {
      line-height: 34px;
      width: 876px;
      height: 34px;
      font-size: 29px;
    }
  }
}

/* Media query for screens with width >= 860px */
@media screen and (min-width: 860px) {
  .getting-started-v2-page {
    border-style: solid;
    border-color: #edebe9;
    border-width: 1rem 4rem 1rem 4rem;
    background-color: #edebe9;
  }
}

.getting-started-background {
  background-color: #edebe9;
}

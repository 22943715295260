.callout-content {
  font-size: 12px;
  padding: 0 10px 0 10px;

  a {
    font-size: 12px;
  }
}

.icon-button {
  color: #0067b8;
  font-size: 21px;
  border: none;
  background-color: #fff;
}

button:focus {
  outline: 1px dotted black;
}
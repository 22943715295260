#submit-code {
  i {
    font-size: 15px;
    position: relative;
    top: 2px;
  }
  text-align: center;
  .icon-green {
    color: #276f01;
    font-size: 20px;
  }
  .icon-default {
    color: #e16565f2;
  }
}

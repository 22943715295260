.step-card {
  flex: 1;

  .icon-container-card {
    @media screen and (min-width: 1084px) {
      height: 174px;
    }

    @media screen and (min-width: 860px) and (max-width: 1084px) {
      height: 148px;
    }

    @media screen and (min-width: 540px) and (max-width: 860px) {
      height: 290px;
    }

    @media screen and (max-width: 540px) {
      height: 190px;
    }

    .card-img {
      @media screen and (min-width: 860px) {
        height: 71px;
        width: 71px;
      }

      @media screen and (min-width: 540px) and (max-width: 860px) {
        min-width: 105px;
        height: 105px;
      }

      @media screen and (max-width: 540px) {
        min-width: 71px;
        height: 71px;
      }
    }
  }

  .step-card-body {
    padding-top: 12px;

    .title-text {
      text-align: left;
    }

    .detail-text {
      text-align: left;
    }
  }

  .action-link {
    padding-top: 24px;
    line-height: 0px;
    text-align: left;

    .cta-style {
      color: #0067b8;
      outline: 0;
    }
  }
}

@import "src/theme";

.centered-text-section {
  justify-content: center;
  text-align: center;
  padding: 0px 10%;

  .centered-heading {
    font-size: 28px;
    padding: 10px;
  }
  .centered-content {
    font-size: 20px;
    padding: 5px;
  }
}

.banner-notification {
  display: flex;
  background-color: #e6e6e6;
  padding: 20px 10%;

  .status-banner {
    padding: 0;

    .icon {
      font-size: 13px;
      margin-right: 5px;
    }

    .glyph-prepend-large::before {
      font-size: 1rem;
    }
  }

  h4 {
    display: none;
  }
}

.banner-icon {
  display: block;
  padding: 0 13px;
}

.banner-text {
  display: block;
  margin: 0;
}

.info-section {
  background: #fff;
}

.info-section-header-container {
  padding-bottom: 0;
}

.card-background {
  max-height: 620px;
}

.skip-to-main {
  z-index: 5;
  position: relative;
  
  .skip-to-main-link {
    background-color: #fff;
    padding:1%;
  }
}

#navigation-card-title {
  padding: 15px;
}

.solutions-tabs {
  background: #f2f2f2;
  padding: 5px;

  .header-section {
    .solutions-header {
      text-align: left;
      margin: 10px;
    }

    .solutions-paragraph {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .solutions-tab-group {
    text-align: left;

    .tablinks {
      color: #564c4c;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      margin: 10px;
      border: none;
      text-decoration: none;
      padding: 5px;

      @media screen and (max-width: 800px) {
        display: block;
      }
    }

    .tab-selected {
      color: #000000;
      border-bottom-color: #0067b8;
      border-bottom-style: solid;
      border-bottom-width: 2px;
    }
  }
}

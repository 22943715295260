@import "src/theme";

@media screen and (min-width: $r-stop-0) {
  .action-card-section {
    margin: 10px !important;
    width: 48% !important;
  }
}

.action-card-section {
  padding: 20px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  .action-card-buttons a {
    color: #0067b8 !important;
    background-color: white;
  }

  .action-card-buttons a:hover {
    color: #005293 !important;
    text-decoration: underline;
    background-color: white;
  }

  .action-card-section-icon {
    display: inline;
    font-size: 40px;
    vertical-align: middle;
    padding-right: 20px;
  }

  .action-card-section-header {
    font-size: 18px;
    font-weight: bold;
  }

  @media screen and (max-width: $r-stop-2) {
    .action-card-section-icon {
      display: block;
      padding-right: 0px;
    }

    .action-card-section-header {
      font-size: 25px;
    }
  }

  .action-card-description {
    margin-left: 0px;

    a {
      color: #0067b8 !important;
    }

    a:hover {
      color: #005293 !important;
    }
  }
}

@import "src/theme";

div.submission-result {
  margin-bottom: 10px;
}

div.result {
  display: flex;
  margin-bottom: 5px;
  padding: 10px;
  width: 100%;

  i {
    font-size: 35px;
    height: 35px;
    width: 35px;
    margin: 0 15px 0 0;
  }
}

#result-details {
  color: $common-txt-clr;
}

.success {
  color: green;
}

.warning {
  color: rgb(255, 120, 0);
}

.error {
  color: red;
}

@media screen and (max-width: $r-stop-1) {
  div.result {
    i {
      margin: 0 15px 0px 0;
    }
  }
}

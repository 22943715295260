.card-body-text {
  color: black;
}

.card-background {
  position: relative;

  @mixin after-gradient {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &.right-gradient::after {
    @include after-gradient;
    background: linear-gradient(to right, rgb(22, 22, 22), transparent);
  }

  &.left-gradient::after {
    @include after-gradient;
    background: linear-gradient(to left, rgb(22, 22, 22), transparent);
  }
}

.text-container-overflow {
  overflow: inherit;
}

@media (min-width: 1084px) {
  .card-body-text {
    color: white;
  }

  .card-body-text-black {
    color: black;
  }

  .material-md-card {
    background-color: transparent;
  }

  .text-container-overflow {
    overflow: hidden;
  }
}

.banner-desc {
  font-size: 16px;
  line-height: 24px;
}

@media screen and (min-width: 1084px) {
  .left-text-align {
    padding-right: 3rem;
  }
}

@media screen and (min-width: 860px) and (max-width: 1083px) {
  .left-text-align {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
.status-banner {
  display: flex;
  padding: 24px 56px;
  background: #e6e6e6;

  @media screen and (max-width: 540px) {
    flex-direction: column;
    padding: 24px 12px;
  }

  .icon {
    width: 24px;
    margin-right: 24px;
  }

  .error-text {
    color: #dc3545;
  }
}

.required-field-alert {
  color: #a80000;
}

.eligibility-precheck-container {
  @media screen and (min-width: 1084px) {
    width: 95%;
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
}

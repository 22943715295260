@import "src/theme";

.text-red {
  color: #dc3545;
}

@media (min-width: 1400px) {
  .h2-max {
    font-size: 29px;
  }
}

.plan-section-container {
  justify-content: center;
}
@media (min-width: 1085px) {
  .plan-section-container {
    padding-left: 3vw;
    padding-right: 3vw;
  }
}

@media (max-width: 1084px) {
  .plan-section-container {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
  }
}

.offer-card-container {
  background-clip: content-box;
}

.offer-card-badge-with-info {
  min-height: 4.5rem;
  border-top-left-radius: 5% 15%;
  border-top-right-radius: 5% 15%;
}
@media (min-width: 860px) {
  .offer-card-title {
    min-height: 2.5em;
  }

  .offer-card-disclaimer {
    min-height: 3em;
  }

  .offer-card-badge {
    min-height: 2.75em;
  }
}

.row {
  margin-left: 0%;
  margin-right: 0%;
}

.text-medium {
  font-size: 0.925rem;
}

.mb-list-element {
  margin-bottom: 0;
}

.current-offers {
  background: #fafafa;

  .title {
    text-align: center;
    padding-bottom: 52px;
  }
}

.faux-column {
  min-height: 2em;
}

.faux-column-mini {
  min-height: 0.75em;
}

.plan-section-disclaimer {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  min-width: 85em;
  text-align: left;
}

.hardware-request-form {
  .hardware-submit-container {
    @media screen and (min-width: 860px) {
      margin-top: 28px;
    }
  }

  .hardware-submit-button {
    height: 40px;
  }
}

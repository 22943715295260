.software-send-eligibility-form {
  padding: 33px 48px;
  background: #f2f2f2;

  .form-title {
    margin-bottom: 16px;
  }
}

.software-and-grants {
  gap: 22px;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 860px) {
    padding: 32px 56px;
  }

  @media screen and (max-width: 860px) {
    flex-direction: column;
    padding: 32px 12px;
  }

  .guide-icon {
    margin-top: 16px;
    width: 48px;
    height: 48px;
  }

  .software-and-grants-card {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13), 0px 1px 1px rgba(0, 0, 0, 0.108);

    .card-content {
      margin: 12px 24px 24px;

      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
      }

      .footer-text {
        padding-top: 14px;
      }

      .description {
        padding-top: 12px;
      }
    }
  }
}

.software-eligibility-error {
  margin-bottom: 0px;
}

.alternate-email-policy p {
  padding-top: 1rem;

  > div {
    color: #333;
  }
}

.action-button {
  text-decoration: none;
}

$default-font-size: 16px;
$default-font-weight: 400;

/**********************************************************************/
$max-content-wd: 1200px;

$header-ht: 75px;
$footer-ht: 44px;

$common-txt-clr: #000000;
$common-link-clr: #005da6;
$secondary-link-clr: #616161;

$content-h3-font-size: 20px;
$content-h3-font-weight: 600;

$icon-font: "MWF-MDL2";

$r-stop-0: 1024px;
$r-stop-1: 750px;
$r-stop-2: 550px;
$r-stop-3: 350px;

/**********************************************************************/
$nav-menu-font-size: 13px;
$nav-menu-font-size-phone: 16px;

$nav-menu-bck-clr-phone: #f6f6f6;
$nav-menu-border-phone: solid 1px #e6e6e6;

/**********************************************************************/
$submenu-bck-clr: #f4f4f4;
$submenu-hover-clr: #eaeaea;
$submenu-border-clr: $submenu-hover-clr;

/**********************************************************************/
$user-panel-bkg-clr: $submenu-bck-clr;
$user-panel-txt-clr: $common-txt-clr;
$user-panel-border: solid 1px #c9c9c9;
$user-panel-wd: 330px;

$user-badge-ht-sm: 36px;
$user-badge-ht-lg: 64px;

/**********************************************************************/
$text-input-border: $user-panel-border;
$text-input-border-hover: solid 1px lighten(royalblue, 20%);
$text-input-padding: 4px 4px 4px 6px;

/**********************************************************************/
@font-face {
  font-family: $icon-font;
  src: url("https://www.microsoft.com/mwf/_h/v2.98/mwf.app/fonts/mwfmdl2-v2.98.woff") format("woff"),
    url("https://www.microsoft.com/mwf/_h/v2.98/mwf.app/fonts/mwfmdl2-v2.98.ttf") format("truetype"),
    url("https://www.microsoft.com/mwf/_h/v2.98/mwf.app/fonts/mwfmdl2-v2.98.svg") format("svg");
}

/**********************************************************************/
@mixin user-photo($width) {
  padding: 0;
  width: $width !important;
  height: $width !important;
  line-height: $width;
  vertical-align: middle;
  text-align: center;
  border-radius: $width / 2;
  background-color: royalblue;
  color: white;
  font-size: 12px;
}

/**********************************************************************/
@mixin base-list() {
  list-style: none;
  margin: 0;
  padding: 0;
}

/**********************************************************************/
@mixin base-button() {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

/**********************************************************************/
@mixin close-button() {
  @include base-button();

  float: right;
  font-size: 20px;
  font-family: "Arial Rounded MT", Arial, Helvetica, sans-serif !important;
  margin-top: 8px;
  padding-bottom: 3px;

  &:hover {
    background-color: white;
  }
}

/**********************************************************************/
@mixin fabric-button() {
  @include base-button();

  background-color: #106ebe;
  color: white;
  padding: 6px 20px 6px 20px;
  text-decoration: underline;
  text-align: center;
  font-weight: bold;
  width: auto;
  display: block;

  &:hover {
    background-color: darken(#106ebe, 5%);
  }
}

@import "src/theme";

#nonprofit-footer {
  height: $footer-ht;
  width: 100%;

  ul {
    float: right;
    list-style: none;
    margin: 0 20px 0 0;

    li {
      vertical-align: middle;
      display: inline-block;
      margin: 0px 0px 0px 20px;

      #managecookielink,
      a {
        font-size: 12px !important;
        color: $secondary-link-clr !important;
        height: $footer-ht;
        line-height: $footer-ht;
        vertical-align: middle;

        &:hover {
          color: $common-link-clr !important;
          text-decoration: underline;
        }
      }
    }
  }
  @media screen and (max-width: $r-stop-1) {
    width: 100%;
    ul {
      float: right;
      li {
        margin: 0px 10px 0px 0px;
      }
    }
  }
}

.action-steps-container-style {
  background-color: #ffffff;
  gap: 22px;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 860px) {
    padding: 10px 56px 40px;
  }

  @media screen and (max-width: 860px) {
    flex-direction: column;
    padding: 10px 12px 40px;
  }

  .action-step {
    flex: 1;
  }
}

.action-steps-header-container {
  padding: 43px 0px;
}

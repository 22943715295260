.content-card-container {
  .partner-title {
    font-style: normal;
    font-weight: 600;
    font-size: 29px;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
  }

  @media screen and (max-width: 860px) {
    .partner-title {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .partner-description {
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  @media screen and (min-width: 1084px) {
    padding: 48px 56px;
  }

  @media screen and (max-width: 1084px) {
    padding: 48px 52px;
  }

  @media screen and (max-width: 860px) {
    padding: 48px 12px;
  }

  @media screen and (max-width: 860px) {
    padding: 48px 12px;
  }

  .content-card-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.follow-us-footer {
  .footer-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .social-media-icon {
    height: 26px;
    width: 26px;
  }

  .footer-margin {
    margin: 12px 0px;

    @media screen and (min-width: 540px) {
      padding: 0px 3rem;
    }

    @media screen and (max-width: 540px) {
      padding: 0px 12px;
    }
  }

  .footer-link-section {
    background: #f2f2f2;
    padding-top: 12px;
    margin: 0px;

    .footer-list-item {
      padding: 5px;
      color: #666666;

      .footer-list-link {
        text-decoration: none;
        color: #666666;
      }

      .footer-list-link:hover {
        text-decoration: underline;
      }
    }
  }
}

#linkedin-join-community {
  background: #e6e6e6;
}

#linkedin-contact-us-section {
  background: #fafafa;

  .contact-us-section-content {
    padding-left: 15%;
    padding-right: 15%;
  }
}

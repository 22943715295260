.account-guard-policy-disclaimer {
  background: #e6e6e6;

  .account-guard-policy-disclaimer-text {
    font-size: 12px;

    a {
      font-size: 12px;
    }
  }
}

.account-guard-banner {
  max-height: 562px;
}

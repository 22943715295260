section#app-context {
  padding: 15px 0 15px 0;

  ul,
  li,
  a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

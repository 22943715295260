.email-verification-dialog {
  p {
    text-align: center;
  }
  .subText {
    font-size: 15px;
    text-align: left;
  }
  .ms-Spinner {
    margin: 0px;
    height: 40px;
  }
  ::placeholder {
    color: #757575;
  }
}

#email-code-verification-error {
  text-align: center;
  color: #d52222;
}

#change-email {
  text-align: center;
  font-size: 12px;
  color: #676666;
}

#error-message-email-update {
  font-size: 13px;
  padding-top: 20px;
}

.email-verification-header {
  text-align: center;  
}

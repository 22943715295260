.navigation-bar {
  background: #f2f2f2;

  .navigation-bar-content {
    border: none;
    text-decoration: underline;
    font-size: 14px;
    line-height: 20px;
  }
}

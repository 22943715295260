.training-section {
  padding: 46.5px 0px 68px;

  .training-header {
    text-align: center;
  }

  .training-content {
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

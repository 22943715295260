.sameline {
  flex-wrap: nowrap;
}

.btn:focus {
  outline: 1px dotted black;
}

.cloud-register-now-banner {
  background: #fafafa;

  @media screen and (min-width: 860px) {
    padding: 19px 56px 64px;
  }

  @media screen and (max-width: 860px) {
    padding: 0px;
  }
}

header-title,
.header-title {
  font-size: 18px;
}
@media (min-width: 1084px) {
  header-title,
  .header-title {
    font-size: 21px;
  }
}
@media (min-width: 1400px) {
  header-title,
  .header-title {
    font-size: 29px;
  }
}

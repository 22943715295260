.custom-carousel-prev {
  position: static;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.custom-carousel-next {
  position: static;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.custom-carousel-indicators {
  position: static;
  margin-bottom: 0px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.custom-carousel-controls {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
}

.dot-display-none-xl {
  @media (min-width: 1400px) {
    display: none;
  }
}

.dot-display-none-lg {
  @media (min-width: 1084px) {
    display: none;
  }
}

.dot-display-none-md {
  @media (min-width: 860px) {
    display: none;
  }
}

.dot-display-none-sm {
  @media (min-width: 540px) {
    display: none;
  }
}

.dot-display-none-xs {
  display: none;
}

@import url("https://static2.sharepointonline.com/files/fabric/office-ui-fabric-core/9.6.1/css/fabric.min.css");
@import "src/theme";

// remove unwanted top margin from uhf footer
#uhf-footer {
  margin-top: 0;
}

.index {
  body,
  html {
    font-size: $default-font-size;
    font-weight: $default-font-weight;
    margin: 0;
    padding: 0;
    color: $common-txt-clr !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    padding: 0;
  }

  h1 {
    font-size: 34px;
    font-weight: initial;
    margin: 0;
    padding: 0;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
  }

  h3 {
    font-weight: $default-font-weight;
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }

  p,
  li,
  div,
  span,
  label,
  td,
  a,
  fieldset,
  legend {
    font-size: $default-font-size;
    font-weight: $default-font-weight;
  }

  button {
    &:focus {
      outline: 1px solid black;
    }
    &.external {
      text-decoration: underline;
    }
  }
}

.hidden {
  display: none;
}

#root {
  display: flex;
  flex-direction: column;

  #container {
    max-width: $max-content-wd;
    width: 100%;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    margin: 0px auto;
    header {
      flex: 0 1 auto;
      margin: 0 20px 0px 20px;
      min-height: 60px;
    }
    #content {
      margin: 0 20px 0px 20px;
      padding-top: 15px;
      flex: 1 1 auto;
    }
    footer {
      flex: 0 1 $footer-ht;
    }
  }
}

.ms-Spinner {
  margin-top: 100px;
}

a.primary-button {
  text-decoration: none;
  background-color: #106ebe;
  color: white;
  padding: 6px 20px 6px 20px;
  text-align: center;
  &:hover {
    background-color: darken(#106ebe, 5%);
  }
  &:focus {
    outline: 2px dotted black;
  }
  i {
    vertical-align: -2px;
    padding-right: 5px;
    font-size: 14px;
  }
}

.slideup,
.slidedown {
  max-height: 0;
  overflow-y: hidden;
  -webkit-transition: max-height 0.5s ease-in-out;
  -moz-transition: max-height 0.5s ease-in-out;
  -o-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
}
.slidedown {
  max-height: 500px;
}

.small,
.small a {
  font-size: 14px;
}

@media screen and (max-width: $r-stop-1) {
  p,
  li,
  div,
  span,
  label,
  td,
  a {
    text-align: inherit;
  }
  h1 {
    font-size: 22px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }
}

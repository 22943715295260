.profile-info-section {
  display: flex;
  padding: 0 0 35px;
  align-items: flex-start;

  .banner-icon {
    display: block;
    padding: 3px 8px 0 0;
  }
}
.x-icon-button {
  float: right;
  border: none;
  background-color: transparent;
}
.while-revalidating {
  padding-bottom: 35px;
}
.thanks-for-interest {
  padding-top: 35px;
}
.display-none {
  display: none;
}

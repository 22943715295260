#text-field-code {
  margin: auto;
  text-align: center;
}

#code-submit-button {
  text-align: center;

  button[type="submit"] {
    font-size: 17px;
    margin-top: 15px;
    width: 120px;
  }
}

.inputfield {
  width: 100%;
  height: 2.5em;
  float: left;
  margin-top: 4px;
}

.verify-code-row {
  margin: auto;
}

.over-300-employees {
  text-align: center;
  margin-bottom: 1rem;

  @media screen and (min-width: 540px) {
    padding: 3rem;
  }

  @media screen and (max-width: 540px) {
    padding: 3rem 12px;
  }

  .over-300-employees-content-style {
    @media screen and (min-width: 540px) {
      padding-left: 24%;
      padding-right: 24%;
    }
  }
}
